import { Box, Container } from '@mui/material';

const AdminConfigModules = () => {
  return (
    <Container maxWidth='md'>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}></Box>
    </Container>
  );
};

export default AdminConfigModules;
