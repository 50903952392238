import { useBackground } from '@kiosk/hooks/useBackground';
import localization from '@kiosk/i18n/localization';
import { Box, Breadcrumbs, Chip, Fab } from '@mui/material';
import SugarPattern from '@kiosk/config/images/sugar_stack_background_linear_40pct.svg';
import SugarCube from '@kiosk/config/images/sugarcrm-logo-white-cube.svg';
import { ColorWhite } from '@kiosk/maple-syrup/build/javascript/sugarColorPalette';
import { styled } from '@mui/material/styles';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import CloseIcon from '@mui/icons-material/Close';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  return {
    height: theme.spacing(4),
    width: theme.spacing(21),
    outlineWidth: '1px',
    outlineStyle: 'solid',
    color: ColorWhite,
    fontWeight: 600,
    fontSize: 16,
    fontStyle: 'normal',
  };
});

/**
  Site Designer page
 * @returns Entry page of Site Designer
 */
const ModuleLayouts = () => {
  useBackground('Admin', {
    backgroundImage: `url(${SugarPattern}), linear-gradient(0deg, #9B23EA 0.32%, #3B58CA 100.13%)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
  });
  return (
    <Box sx={{ height: '100vh', position: 'relative', overflow: 'hidden' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          height: '34px',
          ml: '20px',
          mt: '15px',
          mb: '15px',
        }}>
        <Breadcrumbs aria-label='breadcrumb'>
          <StyledBreadcrumb
            variant='outlined'
            size='big'
            label={localization.get(
              'LBL_KIOSK_SIDEBAR_SITE_DESIGNER',
              'AdminConfig'
            )}
            icon={<FormatPaintIcon color={ColorWhite} size='medium' />}
          />
        </Breadcrumbs>
        <Fab
          // onClick={}
          sx={{
            height: '36px',
            width: '36px',
            boxShadow: 'none',
            backgroundColor: ColorWhite + '33', // 20% opacity in HEX
            color: ColorWhite,
            marginLeft: 'auto',
            marginRight: '20px',
            '&:hover': {
              backgroundColor: ColorWhite + '66', // 10% opacity in HEX
            },
          }}
          aria-label='close'>
          <CloseIcon />
        </Fab>
      </Box>
      <Box
        sx={{
          mt: '40px',
          height: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        {/* <Header
          fontSize='34px'
          color={ColorWhite}
          title={localization.get(
            'LBL_KIOSK_SITE_DESIGNER_TITLE',
            'SiteDesigner'
          )}
        /> */}
        <Box
          sx={{
            mt: '58px',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}></Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          ml: '30px',
          mb: '30px',
          bottom: '0px',
        }}
        component='img'
        src={SugarCube}
      />
    </Box>
  );
};

export default ModuleLayouts;
