export const sidebarDefs = [
  {
    name: 'home',
    label: 'LBL_KIOSK_SIDEBAR_HOME',
    iconName: 'home-lg',
    path: '/',
  },
  {
    name: 'site_designer',
    label: 'LBL_KIOSK_SIDEBAR_SITE_DESIGNER',
    iconName: 'format-lg',
    path: '/admin/config/site-designer',
  },
  {
    name: 'modules',
    label: 'LBL_KIOSK_SIDEBAR_MODULES',
    iconName: 'default-module-lg',
    path: '/admin/config/modules',
  },
  {
    name: 'features',
    label: 'LBL_KIOSK_SIDEBAR_FEATURES',
    iconName: 'features-lg',
    path: '/admin/config/features',
  },
];
