import { useBackground } from '@kiosk/hooks/useBackground';
import localization from '@kiosk/i18n/localization';
import {
  Card,
  CardContent,
  Box,
  Breadcrumbs,
  Chip,
  Fab,
  CardMedia,
  Typography,
  Tooltip,
} from '@mui/material';
import SugarPattern from '@kiosk/config/images/sugar_stack_background_linear_40pct.svg';
import SugarCube from '@kiosk/config/images/sugarcrm-logo-white-cube.svg';
import { styled } from '@mui/material/styles';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import CloseIcon from '@mui/icons-material/Close';
import ThemeImage from '@kiosk/config/images/L1_01_Theme.svg';
import LayoutImage from '@kiosk/config/images/L1_02_Layouts.svg';
import { Link } from 'react-router-dom';
import {
  ColorBaseGray700,
  ColorBaseWhite,
} from '@kiosk/maple-syrup/build/color/js/sugarColorPalette';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  return {
    height: '2.125rem',
    width: 'auto',
    paddingLeft: '0.3125rem',
    color: ColorBaseWhite,
    fontWeight: 600,
    fontSize: 16,
    fontStyle: 'normal',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)', // white at 20% opacity
      cursor: 'pointer',
    },
  };
});

/**
  Site Designer page
 * @returns Entry page of Site Designer
 */
const SiteDesigner = () => {
  useBackground('Admin', {
    backgroundImage: `url(${SugarPattern}), linear-gradient(0deg, #9B23EA 0.32%, #3B58CA 100.13%)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
  });
  return (
    <Box
      sx={{
        fontFamily: 'Inter',
        height: '100vh',
        position: 'relative',
        overflow: 'hidden',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          height: '2.125rem',
          ml: '1.25rem',
          mt: '0.9375rem',
          mb: '0.9375rem',
        }}>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link to='/admin/config/site-designer'>
            <StyledBreadcrumb
              variant='outlined'
              size='big'
              label={localization.get(
                'LBL_KIOSK_SIDEBAR_SITE_DESIGNER',
                'AdminConfig'
              )}
              icon={<FormatPaintIcon color={ColorBaseWhite} size='medium' />}
            />
          </Link>
        </Breadcrumbs>
        <Link style={{ textDecoration: 'none' }} to={'/'} reloadDocument={true}>
          <Fab
            sx={{
              height: '2.25rem',
              width: '2.25rem',
              boxShadow: 'none',
              backgroundColor: 'rgba(255, 255, 255, 0.2)', // white at 20% opacity
              color: ColorBaseWhite,
              marginLeft: 'auto',
              marginRight: '1.25rem',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.4)', // white at 40% opacity
              },
            }}
            aria-label='close'>
            <CloseIcon />
          </Fab>
        </Link>
      </Box>
      <Box
        sx={{
          mt: '2.5rem',
          height: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box
          sx={{
            height: '2.625rem',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Typography
            sx={{
              color: ColorBaseWhite,
              wordWrap: 'break-word',
              fontWeight: '400',
              fontSize: '2.125rem',
              fontStyle: 'normal',
            }}
            component='h4'>
            {localization.get('LBL_KIOSK_SITE_DESIGNER_TITLE', 'SiteDesigner')}
          </Typography>
        </Box>
        <Box
          sx={{
            mt: '3.625rem',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <Link
            style={{
              textDecoration: 'none',
              marginLeft: 'auto',
            }}
            to={'/admin/config/theme'}
            reloadDocument={true}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: '1rem',
                marginLeft: 'auto',
                height: '27.5rem',
                width: '21.875rem',
                boxShadow:
                  '0rem 1.5625rem 3.125rem -0.75rem rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  boxShadow:
                    '0rem 1.5625rem 3.125rem -0.75rem rgba(0, 0, 0, 0.5)',
                },
              }}>
              <CardMedia
                sx={{
                  height: '14.375rem',
                  width: '18.375rem',
                  marginTop: '3rem',
                  marginLeft: '1.75rem',
                  marginRight: '1.75rem',
                }}
                component='img'
                image={ThemeImage}
                alt={localization.get(
                  'LBL_KIOSK_SITE_DESIGNER_THEME',
                  'SiteDesigner'
                )}
              />
              <CardContent
                sx={{
                  height: '2.625rem',
                  padding: '0rem',
                  marginTop: '1rem',
                  fontSize: '1.875rem',
                  fontWeight: 500,
                  color: ColorBaseGray700,
                }}>
                {localization.get(
                  'LBL_KIOSK_SITE_DESIGNER_THEME',
                  'SiteDesigner'
                )}
              </CardContent>
              <CardContent
                sx={{
                  height: '2.5rem',
                  width: '17.5rem',
                  padding: '0rem',
                  marginTop: '1rem',
                  marginBottom: '3rem',
                  fontSize: '0.875rem',
                  textAlign: 'center',
                  color: ColorBaseGray700,
                }}>
                {localization.get(
                  'LBL_KIOSK_SITE_DESIGNER_THEME_DESCRIPTION',
                  'SiteDesigner'
                )}
              </CardContent>
            </Card>
          </Link>
          <Link
            style={{
              textDecoration: 'none',
              marginLeft: '3.125rem',
              marginRight: 'auto',
            }}
            to={'/admin/config/site-designer/module-layouts'}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: '1rem',
                height: '27.5rem',
                width: '21.875rem',
                boxShadow:
                  '0rem 1.5625rem 3.125rem -0.75rem rgba(0, 0, 0, 0.25)',
                '&:hover': {
                  boxShadow:
                    '0rem 1.5625rem 3.125rem -0.75rem rgba(0, 0, 0, 0.75)',
                },
              }}>
              <CardMedia
                sx={{
                  height: '14.375rem',
                  width: '18.375rem',
                  marginTop: '3rem',
                  marginLeft: '1.75rem',
                  marginRight: '1.75rem',
                }}
                component='img'
                image={LayoutImage}
                alt={localization.get(
                  'LBL_KIOSK_MODULE_LAYOUTS',
                  'SiteDesigner'
                )}
              />
              <CardContent
                sx={{
                  height: '2.625rem',
                  padding: '0rem',
                  marginTop: '1rem',
                  fontSize: '1.875rem',
                  fontWeight: 500,
                  color: ColorBaseGray700,
                }}>
                {localization.get('LBL_KIOSK_MODULE_LAYOUTS', 'SiteDesigner')}
              </CardContent>
              <CardContent
                sx={{
                  height: '2.5rem',
                  width: '17.5rem',
                  padding: '0rem',
                  marginTop: '1rem',
                  marginBottom: '3rem',
                  fontSize: '0.875rem',
                  textAlign: 'center',
                  color: ColorBaseGray700,
                }}>
                {localization.get(
                  'LBL_KIOSK_MODULE_LAYOUTS_DESCRIPTION',
                  'SiteDesigner'
                )}
              </CardContent>
            </Card>
          </Link>
        </Box>
      </Box>
      <Link to={'https://sugarcrm.com'} target='_blank'>
        <Tooltip title={'SugarCRM'} placement='right'>
          <Box
            sx={{
              position: 'absolute',
              ml: '1.875rem',
              mb: '1.875rem',
              bottom: '0rem',
            }}
            component='img'
            src={SugarCube}
          />
        </Tooltip>
      </Link>
    </Box>
  );
};

export default SiteDesigner;
