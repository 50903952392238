import {
  selectAppConfig,
  selectIsPreviewMode,
} from '@kiosk/redux/slices/appConfig/appConfigSlice';
import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import HomePageBackground from '@kiosk/config/images/default-bg.jpg';

// Enum defining alignment options available
const ALIGNMENT_OPTION = {
  COVER: 'Cover screen',
  REPEAT: 'Repeat',
  TOP_LEFT: 'TopLeft',
  TOP_RIGHT: 'TopRight',
  BOTTOM_LEFT: 'BottomLeft',
  BOTTOM_RIGHT: 'BottomRight',
  FIXED: 'Fixed',
};

/**
 * useBackground applies the given background style to the app dependent on context of preview mode
 * @param {string} page the name of the page to look up in AppConfig object
 * @param {Object} backgroundStyle an object containing any style props that need to be set (excluding backgroundSize, backgroundPosition, and backgroundAttachment)
 */
export function useBackground(page, backgroundStyle = null) {
  const isPreview = useSelector(selectIsPreviewMode);
  const appConfig = useSelector(selectAppConfig);

  const backdropProps = appConfig.Pages?.[page]?.Backdrop;

  useLayoutEffect(() => {
    let appEl = null;

    // special case of 'Admin' page. We want to make sure we ignore the fact that it is currently in preview mode to make admin page render correctly
    if (!isPreview || page === 'Admin' || page === 'SiteDesigner') {
      appEl = document.getElementById('app');
    } else {
      appEl = document.getElementById('preview-background');
    }

    // If we pass an explicity defined style object, apply that and then return
    if (backgroundStyle !== null) {
      for (const prop in backgroundStyle) {
        appEl.style[prop] = backgroundStyle[prop];
      }
      return;
    }

    if (!backdropProps) {
      return;
    }
    // Otherwise we will apply styles based on the Type defined
    switch (backdropProps.Type) {
      // Url only style background image
      case 'Url':
        appEl.style.backgroundImage = `url(${backdropProps.Url})`;
        appEl.style.backgroundColor = backdropProps.Color;

        // Url Type cares about the alignment and needs to apply very specific stylings to achieve selected effect
        switch (backdropProps.Alignment) {
          case ALIGNMENT_OPTION.COVER:
            appEl.style.backgroundSize = 'cover';
            appEl.style.backgroundPosition = 'center';
            appEl.style.backgroundAttachment = 'fixed';
            appEl.style.backgroundRepeat = 'no-repeat';
            break;
          case ALIGNMENT_OPTION.REPEAT:
            appEl.style.backgroundSize = '';
            appEl.style.backgroundRepeat = 'repeat';
            appEl.style.backgroundPosition = 'center';
            appEl.style.backgroundAttachment = 'fixed';
            break;
          case ALIGNMENT_OPTION.TOP_LEFT:
            appEl.style.backgroundSize = '';
            appEl.style.backgroundPosition = 'left top';
            appEl.style.backgroundRepeat = 'no-repeat';
            appEl.style.BackgroundColor = backdropProps.Color;
            break;
          case ALIGNMENT_OPTION.TOP_RIGHT:
            appEl.style.backgroundSize = '';
            appEl.style.backgroundPosition = 'right top';
            appEl.style.backgroundRepeat = 'no-repeat';
            appEl.style.BackgroundColor = backdropProps.Color;
            break;
          case ALIGNMENT_OPTION.BOTTOM_LEFT:
            appEl.style.backgroundSize = '';
            appEl.style.backgroundPosition = 'left bottom';
            appEl.style.backgroundRepeat = 'no-repeat';
            appEl.style.BackgroundColor = backdropProps.Color;
            break;
          case ALIGNMENT_OPTION.BOTTOM_RIGHT:
            appEl.style.backgroundSize = '';
            appEl.style.backgroundPosition = 'right bottom';
            appEl.style.backgroundRepeat = 'no-repeat';
            appEl.style.BackgroundColor = backdropProps.Color;
            break;
          case ALIGNMENT_OPTION.FIXED:
            appEl.style.backgroundSize = '';
            appEl.style.backgroundPosition = 'center';
            appEl.style.backgroundAttachment = 'fixed';
            appEl.style.backgroundRepeat = 'no-repeat';
            appEl.style.BackgroundColor = 'red';
            break;
          default:
            appEl.style.backgroundSize = 'cover';
            appEl.style.backgroundPosition = 'center';
            appEl.style.backgroundAttachment = 'fixed';
            appEl.style.backgroundRepeat = 'no-repeat';
        }
        break;
      // Color only style background image
      case 'Color':
        appEl.style.backgroundImage = '';
        appEl.style.backgroundColor = backdropProps.Color;
        break;
      // File only background image
      case 'File':
        appEl.style.backgroundImage = `url(${backdropProps.File})`;
        break;
      // File + Color background image (currently unused)
      case 'Combo':
        appEl.style.backgroundImage = `url(${backdropProps.File})`;
        appEl.style.backgroundColor = backdropProps.Color;
        break;
      // Default background style applied out of box
      default:
        // TODO: update how we want to handle "default" background on other pages when that is decided
        if (page === 'Home') {
          appEl.style.backgroundImage = `url(${HomePageBackground})`;
          appEl.style.backgroundColor = '';
          appEl.style.backgroundSize = 'cover';
          appEl.style.backgroundPosition = 'center';
          appEl.style.backgroundAttachment = 'fixed';
          appEl.style.backgroundRepeat = 'no-repeat';
        }
        break;
    }

    return () => {
      if (appEl === null) return;
      appEl.style.backgroundImage = '';
      appEl.style.backgroundColor = '';
      appEl.style.backgroundSize = '';
      appEl.style.backgroundPosition = '';
      appEl.style.backgroundAttachment = '';
      appEl.style.backgroundRepeat = '';
    };
  }, [page, isPreview, backdropProps, backgroundStyle]);
}
